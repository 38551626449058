<template>
	<v-container class="mt-4 ml-2" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Exámenes por revisar</span>
	  		    <v-spacer></v-spacer>

	  		    <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportExcel( puestos  , 'examenes')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="grey"
              class="mr-2"
              @click="abrirReporte( )"
              small
              dark
              tile
            >
              <v-icon small>mdi-file</v-icon>
            </v-btn>

	  		    <v-btn
              color="orange"
              dark
              class="mr-2"
              @click="dialog = true"
              small
              tile
            >
              <v-icon left small>mdi-plus</v-icon>
              Agregar
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>

	  		  </v-card-title>

	  		  <v-card-text>

	  		  	<v-row justify="space-between">
	  		  		<v-col cols="12" md="4" lg="4">
	  		  			<v-radio-group            
	              	v-model="escuela"
		              row
		              class="ml-4"
		            >
		              <v-radio
		                label="Fast"
		                :value="2"
		              ></v-radio>
		              <v-radio
		                label="Inbi"
		                :value="1"
		              ></v-radio>
		            </v-radio-group>
	  		  		</v-col>
	  		  		<v-col cols="12" md="8" lg="5">
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="Buscar"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  append-icon="mdi-magnify"
	  		  			  v-model="search"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  	</v-row>

	  		  	<v-row>
	  		  		<v-col cols="12" md="4" lg="3">
	  		  			<v-autocomplete
                  label="Plantel"
                  filled
                  dense
                  id="plantel"
                  hide-details
                  v-model="plantel"
                  :items="planteles"
                  clearable
                  item-value="idplantel"
                  item-text="plantel"
                ></v-autocomplete>
	  		  		</v-col>

	  		  		<v-col cols="12" md="4" lg="3">
	  		  			<v-text-field
				          v-model="fechaini"
				          dense
				          filled
				          type="date"
				          label="Fecha inicio"
				          clearable
				        ></v-text-field>
	  		  		</v-col>

	  		  		<v-col cols="12" md="4" lg="3">
				        <v-text-field
				          v-model="fechafin"
				          dense
				          hide-details
				          filled
				          type="date"
				          label="Fecha final"
				          clearable
				        ></v-text-field>
	  		  		</v-col>

	  		  		<v-col cols="12" md="4" lg="3">
	  		  			<v-checkbox label="Sin revisar" v-model="filtroEstatus" :value="1"></v-checkbox>
	  		  		</v-col>

	  		  	</v-row>

	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="headers"
							    :items="filterExamenes"
							    class="elevation-0"
							    :search="search"
							    :mobile-breakpoint="100"
			            dense
			            item-class="clase_row"
							  >

							  	<!-- Valores que ocupan botons, chips, iconos, etc -->
							    <template v-slot:item.tipoExamen="{ item }">
							      <v-chip 
							      	v-if="item.tipoExamen == 'ubicacion'"
							      	small
							      	color="red"
							      	dark
							      >Ubicación</v-chip>

							      <v-chip 
							      	v-if="item.tipoExamen == 'interno'"
							      	small
							      	color="black"
							      	dark
							      >Interno</v-chip>

							      <v-chip 
							      	v-if="item.tipoExamen == 'teens'"
							      	small
							      	color="blue"
							      	dark
							      >Teens</v-chip>

							      <v-chip 
							      	v-if="item.tipoExamen == 'reubicacion'"
							      	small
							      	color="green"
							      	dark
							      >Reubicación</v-chip>

							      <v-chip 
							      	v-if="item.tipoExamen == 'empresas'"
							      	small
							      	color="pink"
							      	dark
							      >Empresas</v-chip>
							    </template>

							    <template v-slot:item.actions="{ item }">

							    	<v-btn 
							    		color="red"
							    		dark 
							    		x-small
							    		@click="eliminarExamen( item )"
							    		class="mr-2"
							    		v-if="[32,334].includes( getdatosUsuario.iderp )"
							    	>
								      <v-icon small>mdi-delete</v-icon>
							    	</v-btn>

							    	<v-btn 
							    		color="red"
							    		dark 
							    		x-small
							    		@click="abrirPdf(item)"
							    		class="mr-2"
							    		v-if="item.nivel"
							    	>
								      <v-icon small>mdi-file-pdf-box</v-icon>
							    	</v-btn>

							    	<v-btn 
							    		color="primary" 
							    		x-small
							    		@click="editItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small>mdi-magnify</v-icon>
							    	</v-btn>
							    </template>

							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small
							      >
							        Actualizar
							      </v-btn>
							    </template>

							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialog"
      max-width="600px"
    >
      <v-card>
      	<v-card-title class="text-subtitle-1">
      	  Resultado del alumno
      	</v-card-title>
      	<v-card-text class="black--text">

      		<v-list class="transparent" dense>
			      <v-list-item class="pl-0">
			        <v-list-item-title> Nombre </v-list-item-title>

			        <v-list-item-subtitle >
			          {{ editedItem.nombre }}
			        </v-list-item-subtitle>
			      </v-list-item>

			      <v-list-item class="pl-0" v-if="editedItem.tipoExamen == 'teens'">
			        <v-list-item-title> Alumno </v-list-item-title>

			        <v-list-item-subtitle >
			          {{ editedItem.nombre_alumno }}
			        </v-list-item-subtitle>
			      </v-list-item>

			      <v-list-item class="pl-0" v-if="editedItem.tipoExamen == 'teens'">
			        <v-list-item-title> Edad alumno </v-list-item-title>

			        <v-list-item-subtitle >
			          {{ editedItem.edad_alumno }}
			        </v-list-item-subtitle>
			      </v-list-item>

			      <v-list-item class="pl-0" v-if="editedItem.tipoExamen == 'teens'">
			        <v-list-item-title> Tiempo de estudio </v-list-item-title>

			        <v-list-item-subtitle >
			          {{ editedItem.tiempo_estudio }}
			        </v-list-item-subtitle>
			      </v-list-item>

			      <!-- <v-list-item class="pl-0" v-if="editedItem.tipoExamen != 'teens' && editedItem.tipoExamen != 'reubicacion'">
			        <v-list-item-title> Correo </v-list-item-title>

			        <v-list-item-subtitle>
			          {{ editedItem.correo }}
			        </v-list-item-subtitle>
			      </v-list-item>

			      <v-list-item class="pl-0" v-if="editedItem.tipoExamen != 'teens' && editedItem.tipoExamen != 'reubicacion'">
			        <v-list-item-title> Motivo de aprender </v-list-item-title>

			        <v-list-item-subtitle>
			          {{ editedItem.motivo_aprender }}
			        </v-list-item-subtitle>
			      </v-list-item> -->

			      <v-list-item class="pl-0" v-if="editedItem.tipoExamen != 'reubicacion'">
			        <v-list-item-title> Sucursal </v-list-item-title>

			        <v-list-item-subtitle>
			          {{ editedItem.plantel }}
			        </v-list-item-subtitle>
			      </v-list-item>

			      <v-list-item class="pl-0" v-if="editedItem.tipoExamen != 'reubicacion'">
			        <v-list-item-title> Horario </v-list-item-title>

			        <v-list-item-subtitle>
			          {{ editedItem.horario }}
			        </v-list-item-subtitle>
			      </v-list-item>

			      <v-list-item class="pl-0">
			        <v-list-item-title> Fecha </v-list-item-title>

			        <v-list-item-subtitle>
			          {{ editedItem.fecha_registro }}
			        </v-list-item-subtitle>
			      </v-list-item>

			      <v-list-item class="pl-0" v-if="editedItem.tipoExamen != 'reubicacion'">
			        <v-list-item-title> Nivel por el alumno </v-list-item-title>

			        <v-list-item-subtitle>
			          {{ editedItem.nivel_ingles }}
			        </v-list-item-subtitle>
			      </v-list-item>

			      <v-list-item class="pl-0">
			        <v-list-item-title> Nivel asignado </v-list-item-title>

			        <v-list-item-subtitle>
			          {{ editedItem.nivel }}
			        </v-list-item-subtitle>
			      </v-list-item>

			      <v-list-item class="pl-0" v-if="editedItem.tipoExamen == 'reubicacion'">
			        <v-list-item-title> Nivel reprobado </v-list-item-title>

			        <v-list-item-subtitle>
			          {{ editedItem.nivel_reprobado }}
			        </v-list-item-subtitle>
			      </v-list-item>
			    </v-list>

			    <div class="text-h6">Respuestas correctas: {{ editedItem.cant }}</div>

			    <br/>
			    <div class="text-subtitle-1 black--text"><b>Pregunta libre</b></div>

			    <div>{{ editedItem.respuesta_imagen_1 }}</div>
			    <v-radio-group
			      v-model="editedItem.respuesta"
			      row
			      dense
			    >
			      <v-radio
			        label="OK"
			        value="ok"
			      ></v-radio>
			      <v-radio
			        label="ERROR"
			        value="nok"
			      ></v-radio>
			    </v-radio-group>


		  <v-autocomplete
	        v-if="editedItem.respuesta == 'nok'"
            outlined
            dense
            clearable
            v-model="editedItem.repuesta_incorrecta"
            :items="oprespuestas"
            label="Selecciona una opción"
            persistent-hint
            item-text="alt"
            item-value="id"
          >
          </v-autocomplete>


		  <v-autocomplete
		    v-if="getdatosUsuario && [32,334].includes(getdatosUsuario.iderp)"
            outlined
            dense
            clearable
            v-model="editedItem.nivel"
            :items="niveles"
            label="Selecciona el nivel"
            persistent-hint
            item-text="nombre"
            item-value="id"
          >
          </v-autocomplete>

			<v-text-field
			single-line
			name="name"
			label="Grupo Asignado"
			id="id"
			filled
			dense
			clearable
			hide-details
			v-model="grupoAsignado"
			></v-text-field>
			</br>

			<v-btn
			color="grey"
			dark
			class="mb-2 mr-2 text-capitalize"
			@click="dialogGrupos.estatus = true "
			small
			tile
            >
              <v-icon left>mdi-google-classroom</v-icon>
              Buscar Grupos
            </v-btn>
			</br>

			<div class="text-subtitle-1 black--text"><b>Recomendacion de grupos</b></div>
		
			<v-data-table
			:headers="headersGruposSugerencia"
			:items="recomendacionGrupos"
			class="elevation-0"
			dense
			mobile-breakpoint="200"
			>
			</v-data-table>
	        
      	</v-card-text>
			  <v-card-actions>
			    <v-btn color="black" dark small tile @click="dialog = false">
			    	<v-icon small left>mdi-cancel</v-icon>
			    	Cancelar
			    </v-btn>
			    <v-spacer></v-spacer>
			    <v-btn color="primary" dark small tile @click="save">
			    	<v-icon small left>mdi-content-save</v-icon>
			    	Guardar
			    </v-btn>
			  </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog para diferencias -->
    <v-dialog
      v-model="dialogReporte"
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
      max-width="800"
    >
      <v-card>
        <v-card-title primary-title>
          Reporte
          <v-spacer></v-spacer>
        </v-card-title>
        <!-- Datos principales del usuario -->
        <v-card-text>
          <v-data-table
            :headers="headers2"
            :items="reporte"
            class="elevation-0"
            dense
            mobile-breakpoint="200"
          >
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogReporte = false" small color="green" dark>
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


	 <!-- Dialogo para ver todos los grupos habiles que hay -->
    <GruposHabiles v-if="dialogGrupos.estatus == true"    :dialogGrupos="dialogGrupos"/>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';
  import GruposHabiles      from '@/components/grupos/GruposHabiles.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  var moment = require("moment");
  moment.locale();

  export default {

    mixins: [ validarErrores, funcionesExcel ],

  	components:{
      Alerta,
      carga,
	  GruposHabiles,
    },


    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      
      editedIndex: -1,

      escuela: 1,
	  fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      editedItem: {
        id_forma_pago:0,
				forma_pago:'',
				comentarios:'',
				fecha_alta:null,
				activo_sn:0,
				fecha_baja:null,
				id_usuario_ultimo_cambio:0,
				fecha_ultimo_cambio:null,
      },

      defaultItem: {
        id_forma_pago:0,
				forma_pago:'',
				comentarios:'',
				fecha_alta:null,
				activo_sn:0,
				fecha_baja:null,
				id_usuario_ultimo_cambio:0,
				fecha_ultimo_cambio:null,
      },

      // tabla
			search:'',

      headers: [
        { text: 'ID'           , value: 'id'               },
        { text: 'TIPO'         , value: 'tipoExamen'       },
        { text: 'Nombre'       , value: 'nombre'           },
        { text: 'Folio'        , value: 'folio'            },
        { text: 'Teléfono'     , value: 'telefono'         },
        { text: 'Motivo Apr.'  , value: 'motivo_aprender'  },
        { text: 'Sucursal'     , value: 'plantel'          },
        { text: 'Horario'      , value: 'horario'          },
        { text: 'Fecha'        , value: 'fecha_registro'   },
        { text: 'Nivel'        , value: 'nivel'            },
        { text: 'Convenio'     , value: 'convenio'            },
        { text: 'Actions'      , value: 'actions', sortable: false },
      ],

      examenes: [],

      niveles:[],
      oprespuestas:[],
      planteles: [],
	  recomendacionGrupos: [],
	  grupoAsignado: '',
      plantel:null,

      fechaini: null,
			fechafin: null,

			filtroEstatus: null,


	  headers2: [
        { text: 'Plantel'   , value: 'plantel'    },
        { text: 'Cantidad'  , value: 'cantidad'   },
      ],

	  headersGruposSugerencia: [
        { text: 'ID'        , value: 'id_grupo'          },
		{ text: 'Grupo'     , value: 'grupo'             },
      ], 

      dialogReporte: false,
	  dialogGrupos: {estatus: false}
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar Forma de pago' : 'Editar forma de pago'
      },

      filterExamenes(){

      	let data = this.examenes 

      	if( this.plantel ){

      		data = data.filter( el => { return el.id_plantel == this.plantel })
      	
      	}

      	if( this.fechaini && this.fechafin ){

      		data = data.filter( el => { return el.fecha_registro.substr(0,10)  >=  this.fechaini  && el.fecha_registro.substr(0,10)  <=  this.fechafin  })

      	}

      	if( this.fechaini && !this.fechafin ){
      		
      		data = data.filter( el => { return el.fecha_registro.substr(0,10)  >=  this.fechaini  && el.fecha_registro.substr(0,10)  <=  this.fechaini  })

      	}

      	if( this.filtroEstatus == 1 ){

      		data = data.filter( el => { return !el.nivel })

      	}

      	return data
      },

      reporte( ){

      	let data = this.filterExamenes.filter( el => { return el.tipoExamen != 'reubicacion'})

      	let planteles = this.planteles.filter( el => { return el.idunidad_negocio == this.escuela })

      	for( const i in planteles ){

      		planteles[i]['cantidad'] = data.filter( el => { return el.id_plantel == planteles[i].idplantel }).length

      	}

      	return planteles
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },

      escuela ( val ){
      	val || this.initialize()
      }

    },

    async created () {
    	console.log( this.getdatosUsuario )
    	await this.initialize()
    	await this.getNiveles()
    	await this.getPlanteles()
    	await this.getOpcionRespuestasMalas()
    },

    methods: {
      initialize () {
      	this.dialog = false
      	this.cargar = true
        this.examenes = []
        return this.$http.get('examenes.revision/' + this.escuela ).then(response=>{
        	this.examenes = response.data

        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPlanteles () {
      	this.cargar = true
        this.planteles = []
        return this.$http.get('planteles.oficiales').then(response=>{
        	console.log( response.data )
        	this.planteles = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


      getNiveles () {
      	this.dialog = false
      	this.cargar = true
        this.niveles = []
        return this.$http.get('examenes.niveles' ).then(response=>{
        	this.niveles = response.data
        	console.log(this.niveles)
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getOpcionRespuestasMalas () {
      	this.cargar = true
        this.oprespuestas = []
        return this.$http.get('examenes.oprespuestas' ).then(response=>{
        	this.oprespuestas = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.examenes.indexOf(item)
        this.editedItem = Object.assign({}, item)
	      this.dialog = true

		  let info = this.editedItem

		  this.getRecomendacionGrupos(info)

	    // console.log( this.editedItem )
      },

      deleteItem (item) {
        this.editedIndex = this.puestos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
	      this.cargar = true
        // Lo mandapos por el EP
        this.editedItem.escuela = this.escuela

        this.$http.post('examenes.asignarnivel', this.editedItem).then(response=>{
        	this.validarSuccess('Examen revisado correctamente')
        	this.initialize()
        	this.cargar = false
        	this.close()
        }).catch( error =>{
        	this.validarError( error.response.data.message )
      	}).finally( () => { this.cargar = false })
      },


	  getRecomendacionGrupos (info) {
      	this.cargar = true
        this.recomendacionGrupos = []

		const payload = {
          id_plantel: info.id_plantel,
		  nivel: info.nivel,
		  horario: info.horario,
		  escuela: this.escuela,
		  fecha: this.fecha,
		  tipo_examen: info.tipoExamen
        };

        return this.$http.post('examenes.recomendaciongrupos', payload).then(response=>{
        	this.recomendacionGrupos = response.data
			this.grupoAsignado = this.recomendacionGrupos.length > 0 ? this.recomendacionGrupos[0].grupo : 'No hay Grupo Asignado';
			console.log(this.grupoAsignado)
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


      eliminarExamen ( item ) {
	      this.cargar = true
        // Lo mandapos por el EP
        item.escuela = this.escuela

        this.$http.post('examenes.eliminar', item ).then(response=>{

        	this.initialize()

        	this.cargar = false

        	this.close()

        }).catch( error =>{
        	this.validarError( error.response.data.message )
      	}).finally( () => { this.cargar = false })
      },

      abrirPdf( item ){
        window.open ( item.liga , '_blank' )
      },


      abrirReporte(){
      	this.dialogReporte = true
      }
    }
  }
</script>


<style>
  .falta_examen{
  	background-color: #DF9A07 !important;
  	color: white;
  }

</style>
